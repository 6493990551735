.ant-radio-button-wrapper-checked {
  &:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: rgba(244, 245, 248, 1) !important;
  }
}

.customTable {
  &:nth-of-type(2n) {
    background: rgba(250, 251, 252, 1);
  }
}
