.Tv {
    // position: relative;
    min-width: 1725.5pt;
    min-height: 970.5pt;
    // width: 100%;
    // height: auto;
    overflow-y: scroll;
    overflow-x: scroll;
    position: absolute;
    // display: -webkit-box;
    top: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 12;
    background-image: url('../../../../Common/images/back.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;      
    .content{
        display: flex;
        justify-content: space-between;
        padding-left: 1%;
        padding-right: 1%;
        z-index: 13;
        .left{
            // float: left;
            margin-top: 3%;
            width: 49.5%;
            height: auto;
            bottom: 1%;          
            z-index: 13;
            background-image: url('../../../../Common/images/left.png');
            background-repeat: no-repeat;
            background-size: 100%;
            text-align: center;
            padding: 1%;
            .title{
                font-size: 35.5pt;
                // margin-top: 1%;
                margin-top: 2%;                
                color: #fcff00;
                // -webkit-mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#fcff00), to(#ffb330));
                background-image:-webkit-linear-gradient(bottom,#ffb330,#fcff00);
                -webkit-background-clip:text;
                -webkit-text-fill-color:transparent;
                display: flex;
                justify-content: space-between;
                .imgleft{
                    width: 15%;
                    background-image: url('../../../../Common/images/star-left.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-left: 15%;
                    margin-top: -1%;
                }
                .imgright{
                    width: 15%;
                    background-image: url('../../../../Common/images/star-right.png');
                    background-repeat: no-repeat;
                    background-size: 100%;
                    margin-right: 15%;
                    margin-top: -1%;
                }
                .text{
                    width: 40%;
                    background-image: url('../../../../Common/images/star-middle.png');
                    background-repeat: no-repeat;
                    background-size: 70%;
                    background-position-x: center;
                    background-position-y: 20px;
                    padding-bottom: 6.5%;
                }
            }
            .table{
                color: #fff;
                font-size: 21.5pt;
                display: flex;
                justify-content: space-between;
                margin-bottom: 4.5%;
                padding-left: 2%;
                padding-right: 2%;
                :nth-child(1){
                    margin-right: 10%;                  
                }
                :nth-child(2){
                    margin-right: 8%;                  
                }
                :nth-child(3){
                    color: #fcff00;
                    margin-right: 8%; 
                }
                :nth-child(5){
                    color: #fcff00;
                }
            }
            .score{
                color: #fff;
                font-size: 25pt;
                text-align: center;
                display: flex;
                justify-content: space-between;
                padding-left: 2%;
                padding-right: 2%;
                height: auto;
                // height: 662pt;
                .scorel{
                    width: 45%;
                    // height: auto;
                    :nth-child(1){
                        font-size: 29pt;
                        margin-bottom: 3.9%;
                    }                    
                    .item{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 3.4%; 
                        :nth-child(1){
                            width: 20%;
                        }  
                        :nth-child(2){
                            width: 60%;
                        }   
                        :nth-child(3){
                            width: 20%;
                        }                
                    }
                }
                .scorer{
                    width: 45%;
                    // height: auto;
                    :nth-child(1){
                        font-size: 29pt;
                        margin-bottom: 3.9%;
                    }                    
                    .item{
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 3.4%; 
                        :nth-child(1){
                            width: 20%;
                        }  
                        :nth-child(2){
                            width: 60%;
                        }   
                        :nth-child(3){
                            width: 20%;
                        }                
                    }
                }
                .scorem{
                    background-image: url('../../../../Common/images/line.png');
                    background-repeat: no-repeat;
                    background-size: 70%;
                    background-position: center;
                    width: 15px;
                }
            }
        }
    }
    .bigtitle{        
        width: 100%;
        color: #fcff00;
        background-image:-webkit-linear-gradient(bottom,#ffb330,#fcff00);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
        font-size: 43.5pt;
        margin-top: 0.8%;
        z-index: 14;
        display: flex;
        justify-content: space-between;
    }
    span{
        width: 80%;
        text-align: center;
    }
    .btn{
        background-color: transparent;
        font-size: 15pt;
        width: 10%;
        color: #000;
        border: none;
        text-align: left;
        top:0px;
        margin-top: -30pt;
        padding-left: 20pt
    }  
    .btnO{
        background-color: transparent;
        width: 10%;
        color: transparent;
        border: none;
        clear: both;
    }   
  }
  
.hidden{
    display: none;
    color:transparent;
}
@primary-color: rgba(0,201,217,1);@border-color-base: rgba(239,239,244,1);@background-color-light: rgba(251,251,253,1);@heading-color: rgba(101,108,153,1);