/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.content {
  margin-left: 24px;
}
.schoolContent {
  margin-top: 24px;
}
:global .ant-layout {
  min-height: 100vh;
  transition: all 0.2s;
}
:global .ant-menu {
  padding: 0;
}
:global .ant-menu-item {
  height: 45px;
  line-height: 45px;
}
