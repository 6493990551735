@import '~antd/dist/antd.less';

.switch {
  min-width: 38px;
  height: 20px;
  background-color: #fff;
  border-color: rgba(222, 227, 235, 1);

  &:after {
    width: 16px;
    height: 16px;
    background-color: rgba(0, 201, 217, 1);
  }

  :global {
    .ant-switch-inner {
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.ant-modal-root {
  .ant-modal-header {
    padding: 18px 20px;

    .ant-modal-title {
      font-size: 18px;
      color: #3d4465;
    }
  }

  .ant-modal-footer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-btn {
      margin: 0 10px;
      height: 40px;
      width: 100px;
      border-radius: 2px;
    }
  }
}
// 下拉框
.ant-select-selection,
.ant-input {
  height: 36px !important;
  border-radius: 2px !important;
  border: 1px solid rgba(235, 237, 241, 1) !important;

  .ant-select-selection__rendered {
    line-height: 36px !important;
  }
}

.ant-select-selection {
  &.ant-select-selection--multiple {
    height: auto !important;
  }
}
// 数字输入框
.ant-input-number {
  height: 36px !important;
  border-radius: 2px !important;
  border: 1px solid rgba(235, 237, 241, 1) !important;
  input {
    height: 34px !important;
    line-height: 36px !important;
  }
}
